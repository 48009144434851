import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import '../style/home.css';

const PlayWin = () => {
    const navigate = useNavigate();
    const [walletBalance, setWalletBalance] = useState("0");
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [amount, setAmount] = useState("");
    const [timeRemaining, setTimeRemaining] = useState("00:00");
    const [endTime, setEndTime] = useState(null);
    const [gameId, setGameId] = useState("");
    const [gameHistory, setGameHistory] = useState([]);
    const [myHistory, setMyHistory] = useState([]);
    const [activeTab, setActiveTab] = useState("game");
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isPreparingResult, setIsPreparingResult] = useState(false);
    const [lastProcessedGameId, setLastProcessedGameId] = useState(null);
    const [lastGameId, setLastGameId] = useState("");
    const [freshGames, setFreshGames] = useState("0");
    const [isWin, setIsWin] = useState(false);
    const [minBet, setMinBet] = useState(0);

    // Refs to store interval IDs
    const fetchIntervalRef = useRef(null);
    const timerIntervalRef = useRef(null);

    const convertTimeToDate = (timeStr) => {
        if (!timeStr) return null;
        
        const [time, period] = timeStr.split(' ');
        const [hours, minutes] = time.split(':');
        
        const now = new Date();
        let hour = parseInt(hours);
        
        if (period.toLowerCase() === 'pm' && hour !== 12) {
            hour += 12;
        }
        if (period.toLowerCase() === 'am' && hour === 12) {
            hour = 0;
        }
        
        const targetTime = new Date();
        targetTime.setHours(hour);
        targetTime.setMinutes(parseInt(minutes));
        targetTime.setSeconds(0);
        targetTime.setMilliseconds(0);
        
        // If target time is in the past, add a day
        if (targetTime.getTime() <= now.getTime()) {
            targetTime.setDate(targetTime.getDate() + 1);
        }

       // console.log("Target Time:", targetTime.toLocaleString());
        return targetTime.getTime();
    };

    const updateTimer = useCallback(() => {
        if (!endTime) {
            setTimeRemaining("00:00");
            return;
        }
        
        const now = new Date().getTime();
        const distance = endTime - now;
        
      //  console.log("Timer Update - Current Time:", new Date(now).toLocaleString());
     //   console.log("Timer Update - End Time:", new Date(endTime).toLocaleString());
     //   console.log("Timer Update - Distance (ms):", distance);

        if (distance <= 0) {
            setTimeRemaining("00:00");
            startPolling();
            return;
        }

        const minutes = Math.floor(distance / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Show preparing result dialog in last 10 seconds
        if (distance <= 10000 && !isPreparingResult) {
            setIsPreparingResult(true);
            setIsOverlayVisible(true);
            Swal.fire({
                title: 'Preparing Result',
                text: 'Please wait...',
                allowOutsideClick: false,
                showConfirmButton: false
            });
        }

        const timeStr = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        console.log("Setting time:", timeStr);
        setTimeRemaining(timeStr);
    }, [endTime, isPreparingResult]);

    const startPolling = useCallback(() => {
        console.log("Starting polling...");
        // Clear any existing intervals
        if (fetchIntervalRef.current) {
            console.log("Clearing existing fetch interval");
            clearInterval(fetchIntervalRef.current);
        }
        if (timerIntervalRef.current) {
            console.log("Clearing existing timer interval");
            clearInterval(timerIntervalRef.current);
        }

        // Start polling every second when no game is running
        fetchIntervalRef.current = setInterval(fetchGameData, 1000);
        console.log("Polling interval set");
    }, []);

    const startTimer = useCallback(() => {
        console.log("Starting timer...");
        // Clear polling interval when game starts
        if (fetchIntervalRef.current) {
            console.log("Clearing fetch interval");
            clearInterval(fetchIntervalRef.current);
        }
        
        // Clear existing timer if any
        if (timerIntervalRef.current) {
            console.log("Clearing existing timer interval");
            clearInterval(timerIntervalRef.current);
        }
        
        // Start timer update
        console.log("Setting new timer interval");
        timerIntervalRef.current = setInterval(updateTimer, 1000);
    }, [updateTimer]);

    useEffect(() => {
        console.log("Initial effect running");
        // Initial fetch
        fetchGameData().then(gameRunning => {
            console.log("Initial fetch complete, game running:", gameRunning);
            if (gameRunning) {
                startTimer();
            } else {
                startPolling();
            }
        });

        return () => {
            console.log("Cleaning up intervals");
            if (fetchIntervalRef.current) clearInterval(fetchIntervalRef.current);
            if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
        };
    }, [startPolling, startTimer]);

    const fetchGameData = async () => {
        try {
            const formData = new FormData();
            formData.append("session", localStorage.getItem("token"));
            formData.append("mobile", localStorage.getItem("mobile"));
            formData.append("freshGames", freshGames);
            formData.append("lastGameId", lastGameId);

            const response = await axios.post("https://sattads.com/manage/api2/single.php", formData);
            const data = response.data;

            if (data) {
                setWalletBalance(data.wallet || "0");
                localStorage.setItem("wallet", data.wallet);
                
                // Set minimum bet amount
                if (data.min_lucky_bet) {
                    setMinBet(Number(data.min_lucky_bet));
                }

                if (data.data) {
                    const filteredHistory = data.data
                        .filter(game => game.result !== "?")
                        .map(game => ({
                            period: game.game_id,
                            result: game.result
                        }));
                    setGameHistory(filteredHistory);
                }

                if (data.bids) {
                    const myBets = data.bids.map(bid => {
                        let status;
                        switch (bid.status) {
                            case "0":
                                status = "Pending";
                                break;
                            case "1":
                                status = "Won";
                                break;
                            case "2":
                                status = "Lost";
                                break;
                            default:
                                status = "Unknown";
                        }
                        
                        return {
                            period: bid.game_id,
                            bet: bid.digit,
                            amount: bid.amount,
                            status: status
                        };
                    });
                    setMyHistory(myBets);
                }

                // Handle game state
                if (data.game_running === "0") {
                    console.log("No game running");
                    setAmount("");
                    setEndTime(null);
                    setTimeRemaining("00:00");
                    startPolling();
                    return false;
                } else {
                    console.log("Game running");
                    console.log("End time from API:", data.end_time);
                    const newEndTime = convertTimeToDate(data.end_time);
                    console.log("Converted end time:", new Date(newEndTime).toLocaleString());
                    
                    setEndTime(newEndTime);
                    setGameId(data.game_id);

                    // Reset preparing result state when new game starts
                    if (data.game_id !== lastProcessedGameId) {
                        setIsPreparingResult(false);
                        setIsOverlayVisible(false);
                        setLastProcessedGameId(data.game_id);
                        if (Swal.isVisible()) {
                            Swal.close();
                        }
                        startTimer();
                    }

                    // Handle winning popup
                    if (data.last_game && data.last_game[0] && data.last_game_winning > 0 && !isWin) {
                        console.log("Winning amount:", data.last_game_winning);
                        setIsWin(true);
                        showWinningPopup(data.last_game_winning);
                    }

                    setLastGameId(data.game_id);
                    return true;
                }
            }
            return false;
        } catch (error) {
       //     console.error("Error fetching game data:", error);
            return false;
        }
    };

    const showWinningPopup = (amount) => {
        Swal.fire({
            title: 'Congratulations!',
            text: `You won ₹${amount}!`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        }).then(() => {
            setIsWin(false);
        });
    };

    const handleBet = async () => {
        if (!selectedNumber || !amount) {
            Swal.fire({
                toast: true,
                title: 'Error',
                text: 'Please select a number and enter amount',
                icon: 'error'
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append("session", localStorage.getItem("token"));
            formData.append("mobile", localStorage.getItem("mobile"));
            formData.append("amount", amount);
            formData.append("digit", selectedNumber);
            formData.append("game_id", gameId);

            const response = await axios.post("https://sattads.com/manage/api2/bet_add.php", formData);
            const data = response.data;
            console.log(data);

            if (data.success === "1") {
                setWalletBalance(data.wallet);
                localStorage.setItem("wallet", data.wallet);

                // Update my bets history
                if (data.bids) {
                    const myBets = data.bids.map(bid => {
                        let status;
                        switch (bid.status) {
                            case "0":
                                status = "Pending";
                                break;
                            case "1":
                                status = "Won";
                                break;
                            case "2":
                                status = "Lost";
                                break;
                            default:
                                status = "Unknown";
                        }
                        
                        return {
                            period: bid.game_id,
                            bet: bid.digit,
                            amount: bid.amount,
                            status: status
                        };
                    });
                    setMyHistory(myBets);
                }
                Swal.fire({
                    title: 'Success',
                    text: 'Bet placed successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                setAmount("");
                setSelectedNumber(null);
            } else {
                Swal.fire({
                    toast: true,
                    title: 'Error',
                    text: data.msg,
                    icon: 'error'
                });
            }
        } catch (error) {
         //   console.error("Error placing bet:", error);
            Swal.fire({
                toast: true,
                title: 'Error',
                text: 'Check your internet connection',
                icon: 'error'
            });
        }
    };

    const handleNumberClick = (number) => {
        setSelectedNumber(number.toString());
    };

    const isValidBet = useCallback(() => {
        return amount && Number(amount) >= minBet;
    }, [amount, minBet]);

    return (
        <div className="RootView">
            <div className="RootWidth">
                <div className="loginDiv">
                    <div className="play-win-container">
                        <div className="toolbar">
                            <button onClick={() => navigate(-1)} className="back-button">
                                &lt;
                            </button>
                            <h1>LUCKY NUMBER</h1>
                        </div>

                        <div className="wallet-timer-card">
                            <div className="wallet-section">
                                <div className="wallet-title">Wallet Balance</div>
                                <div className="wallet-amount">{walletBalance}₹</div>
                                <button className="recharge-button">Recharge Now</button>
                            </div>
                            <div className="timer-section">
                                <div className="timer-title">Time Remaining</div>
                                <div className="timer-display">
                                    {timeRemaining.split(":").map((digit, index) => (
                                        <React.Fragment key={index}>
                                            <div className="timer-digit">{digit}</div>
                                            {index < timeRemaining.split(":").length - 1 && <span className="timer-separator">:</span>}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="game-id">{gameId}</div>
                            </div>
                        </div>

                        {isOverlayVisible && (
                            <div className="overlay">
                                <div className="overlay-content">
                                    <p>Preparing result and new game</p>
                                    <p>Please wait...</p>
                                </div>
                            </div>
                        )}

                        <div className="number-selection">
                            <div className="number-grid">
                                {[...Array(10)].map((_, i) => (
                                    <button
                                        key={i}
                                        className={`number-button ${selectedNumber === i.toString() ? 'selected' : ''}`}
                                        onClick={() => setSelectedNumber(i.toString())}
                                        disabled={isOverlayVisible}
                                    >
                                        {i}
                                    </button>
                                ))}
                            </div>
                            <div className="bet-controls">
                                <input
                                    type="number"
                                    placeholder="Enter Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    disabled={isOverlayVisible}
                                />
                                <button 
                                    onClick={handleBet} 
                                    className={`bet-button ${(!selectedNumber || !isValidBet()) ? 'disabled' : ''}`}
                                    disabled={isOverlayVisible || !selectedNumber || !isValidBet()}
                                >
                                    BET
                                </button>
                            </div>
                            {minBet > 0 && (
                                <div className="min-bet-warning">
                                    Minimum bet amount: ₹{minBet}
                                </div>
                            )}
                        </div>

                        <div className="history-tabs">
                            <button
                                className={`tab-button ${activeTab === 'game' ? 'active' : ''}`}
                                onClick={() => setActiveTab('game')}
                            >
                                Game History
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'my' ? 'active' : ''}`}
                                onClick={() => setActiveTab('my')}
                            >
                                My History
                            </button>
                        </div>

                        {activeTab === 'game' ? (
                            <div className="game-history">
                                <div className="history-header">
                                    <div>Period</div>
                                    <div>Result</div>
                                </div>
                                <div className="history-content">
                                    {gameHistory.map((item, index) => (
                                        <div key={index} className="history-row">
                                            <div>{item.period}</div>
                                            <div>{item.result}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="my-history">
                                <div className="history-header">
                                    <div>Period</div>
                                    <div>My Bet</div>
                                    <div>Amount</div>
                                    <div>Status</div>
                                </div>
                                <div className="history-content">
                                    {myHistory.map((item, index) => (
                                        <div key={index} className="history-row">
                                            <div>{item.period}</div>
                                            <div>{item.bet}</div>
                                            <div>{item.amount}</div>
                                            <div className={`status-${item.status.toLowerCase()}`}>
                                                {item.status}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayWin;
