import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Carousel } from 'react-bootstrap';


class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            number: "Loading",
            wallet: "0",
            results:[],
            images:[],
            width_b:"0%",
            width:"0%",
            nav_display:"none",
            pointEvents:"auto",
            transfer_point:"none",
            blockPosition:"relative",
            whatsapp:"",
            homeMsg:"",
            pupup:"",
            showPopup:true,
            name:"",
            app_verify:"0",
            call:"",
            date: new Date()
        }


    }

    componentDidMount() {

        this.timer = setInterval(() => this.setDate(), 1000);

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("f_token", localStorage.getItem("ftoken"));

        console.log("https://sattads.com/manage/"+"/api2/home.php");

        axios
            .post("https://sattads.com/manage/"+"/api2/home.php", form,
            )
            .then((response) => {
                
                if(response.status === 200){

                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }

                    console.log(response);

                    localStorage.setItem("wallet", response.data.wallet)
                    localStorage.setItem("min_deposit", response.data.min_deposit)
                    localStorage.setItem("min_bet", response.data.min_bet)
                    localStorage.setItem("max_bet", response.data.max_bet)
                    this.setState({
                        transfer_point: response.data.transfer_points_status,
                        results:response.data.result,
                        images:response.data.images,
                        app_verify:response.data.app_verify,
                        homeMsg:response.data.homeline,
                        wallet: response.data.wallet,
                        name:response.data.name,
                        popup:response.data.popup_image,
                        whatsapp:response.data.whatsapp,
                        call:"tel://+91"+response.data.whatsapp
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }

    componentWillUnmount() {
        clearInterval(this.timer);
      }

      formatTime(date) {
        return date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });
      }

      formatDate(date) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      }
  setDate() {
    this.setState({
      date: new Date()
    });
  }

    render() {

        let switchNav = () =>{
            if (this.state.width === "0%"){
                this.setState({
                    width:"100%",
                    width_b:"80%",
                    nav_display:"flex",
                    blockPosition:"fixed",
                    pointEvents:"none"
                })
            } else {
                this.setState({
                    width:"0%",
                    width_b:"0%",
                    nav_display:"none",
                    blockPosition:"relative",
                    pointEvents:"auto"
                })
            }
        }

        let marketCloseError = ()=>{
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Market Already Closed"
            })
        }

        let closePopup = ()=>{
            this.setState({
                showPopup:false
            })
        }

        let handleRefresh = ()=>{
            window.location.reload();
        }
        // if (this.state.showPopup){
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     document.body.style.overflow = 'unset';
        // }
        //
        // window.addEventListener('popstate', (event) => {
        //     if (event.state) {
        //         if (this.state.showPopup){
        //             this.setState({
        //                 showPopup:false
        //             })
        //         } else {
        //             window.history.go(-1)
        //         }
        //     }
        // }, false);

   

        return (<>
            <div className="RootView">
                <div className="RootWidth">
                <PullToRefresh onRefresh={handleRefresh}>
                    <div className="loginDiv" style={{ position:this.state.blockPosition, width:"100%", paddingBottom:"15%"}}>

                        {/*{ this.state.popup !== "" && this.state.showPopup ?*/}
                        {/*<div className="popup">*/}
                        {/*    <img className="cancel" onClick={closePopup} src="../images/cancel.png" />*/}
                        {/*    <img className="main" src={"https://sattads.com/manage/"+"/admin/"+this.state.popup} />*/}
                        {/*</div> : "" }*/}

                        { this.state.app_verify !== "0" ? (
                        <div className="sidenav" style={{width: this.state.width, position:"fixed"}} onClick={switchNav}>
                            <div className="main-content" style={{width: this.state.width_b, pointerEvents:"auto", overflowY:"scroll"}} >

                                <div className="nav-top-head"  style={{display:this.state.nav_display}}>
                                    <div className="nav-top-child1">
                                        <span>{this.state.name}</span>
                                        <span>{localStorage.getItem("mobile")}</span>
                                    </div>
                                   
                                </div>

                                <Link to={"/profile"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/profile.png" />
                                        <span>My Profile</span>
                                    </div>
                                </Link>

                                <Link to={"/wallet"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/wallet.png" />
                                        <span>My Wallet</span>
                                    </div>
                                </Link>

                                <Link to={"/winnings"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/wallet.png" />
                                        <span>My Winnings</span>
                                    </div>
                                </Link>

                                <Link to={"/gameHistory"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/hgame.png" />
                                        <span>Game History</span>
                                    </div>
                                </Link>
                               {/* <Link to={"/Transactions"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/transaction (2)-min.png" />
                                        <span>Transaction History</span>
                                    </div>
                                </Link> */}
                                <Link to={"/gameRates"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/rgame.png" />
                                        <span>Game Rate</span>
                                    </div>
                                </Link>

                         

                                <Link  to={"/chart"} state={{url:"https://sattads.com/manage/"+"/api/chart2/getChart.php"}}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/hgame.png" />
                                        <span>Charts</span>
                                    </div>
                                </Link>

                                <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/im_addfunds.png" />
                                        <span>Add Points</span>
                                    </div>
                                </Link>
                                                      
                          {/* <Link to={"/depositHistory"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/cashback-min.png" />
                                        <span>Deposit History</span>
                                    </div> </Link> */}

                                {/* <Link to={"/withdraw"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/im_withdrawfunds.png" />
                                        <span>Withdraw Points</span>
                                    </div>
                                </Link> */}

                           
                                  {/* <Link to={"/withdrawHistory"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/cashback-min.png" />
                                        <span>Withdraw History</span>
                                    </div>
                                </Link> */}

                                <Link to={"/howToPlay"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/play_button.png" />
                                        <span>How To Play</span>
                                    </div>
                                </Link>

                                <Link to={"/Notice"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/info_icon.png" />
                                        <span>Notice</span>
                                    </div>
                                </Link>

                                <a href="https://sattads.com/apk/sattads.apk" target="_blank" >
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/cell-phone.png" />
                                        <span>Download App</span>
                                    </div>
                                </a>

                                <Link to={"/refer"}>
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/share (5)-min.png" />
                                        <span>Refer & Earn</span>
                                    </div>
                                </Link> 

                
                                <Link to={"/"} state={{logout:'true'}} >
                                    <div className="nav-item" style={{display:this.state.nav_display}}>
                                        <img src="../images/logout.png" />
                                        <span>Logout Account</span>
                                    </div>
                                </Link>

                            </div>

                        </div>
                        ) : "" }
                        <div className="toolbar">

                            <div>
                                { this.state.app_verify !== "0" ? (
                                <img src="../images/three_dot.png" onClick={switchNav}/>
                                ) : "" }
                                <span>{process.env.REACT_APP_NAME}</span>
                            </div>
                            { this.state.app_verify !== "0" ? (
                            <Link to={"/wallet"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{this.state.wallet}</span>
                                </div>
                            </Link>
                            ) : "" }


                        </div>
                    
                        <div className="marquee">
                                <span>{this.state.homeMsg}</span>
                            </div>


                       {/* { this.state.app_verify !== "0" ? ( */}
                        <div className="actions">
                       
                        <Carousel interval={3000}>
                            {this.state.images && this.state.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                {
                                    image.refer ? (
                                        <a href={image.refer} target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="d-block w-100"
                                            src={`${"https://sattads.com/manage/"}/admin/${image.image}`}
                                            alt={`Slide ${index + 1}`}
                                        />
                                        </a>
                                    ) : (
                                        <img
                                        className="d-block w-100"
                                        src={`${"https://sattads.com/manage/"}/admin/${image.image}`}
                                        alt={`Slide ${index + 1}`}
                                        />
                                    )
                                    }
                                </Carousel.Item>
                            ))}
                    </Carousel>

                           
                        </div>

{/* ) : "" } */}
                        <div style={{padding:"5px"}}>

                        <div className="clock_section">
        <p> {this.formatDate(this.state.date)} {this.formatTime(this.state.date)} </p>
        <a href={ this.state.whatsapp}>
                                                <div className="homediv">
                                                    <img src="../images/whatsapp (14)-min.png" style={{padding:"5px"}} />
                                                    <span>Whatsapp</span>
                                                </div>
                                            </a>
      </div>

      <Link to={'/luckyNumber'}>
            <div className="homediv lucky-button">
                            <img src="../images/greenback.png" style={{padding:"5px"}} />
                            <span>Play Lucky Number</span>
                            <img src="../images/greenback.png" style={{padding:"5px"}} />
            </div>
                            </Link>

                            {this.state.results &&

                                this.state.results.map((result) => {
                                    const isRed = result.trs === '**'; // Check if trs is '**'
                                    result.brcs = "{ " + result.yrs + " }"
                                    let marketStatus = ""
                                  if(result.is_close === "0") { marketStatus = "Market Closed" } else { marketStatus = "Market is Running" }
                                    return (

                            <div className="market_box" key={result.market}>

                                <div className="market_name2">
                                <span className="market_name">{result.market}</span>
                                </div>

                                   
                                 

                                <div  className="main">
                                <div className="inner_market_box" style={{textAlign:"left"}}>
                                    <Link to={"/chart"} state={{url:"https://sattads.com/manage/"+"/api/chart2/getChart.php?market="+result.market}}>
                                        <img style={{width: "35px"}} src="../images/chart_square.png" />
                                    </Link>
                                    
                                </div>
                                <div className="inner_market_box" style={{textAlign:"center"}}>
                                
                                <span className="market_result"> 
                                {result.brcs} ➔  
                                <span className={isRed ? 'red' : 'green'}>
                                    [ {result.trs} ]
                                </span>
                                </span>
                                    {result.is_close === "0" ?
                                        <span className="market_status" style={{color:"red"}}>{marketStatus}</span>
                                        :
                                        <span className="market_status" style={{color:"green"}}>{marketStatus}</span>
                                    }

<div className="times">
                                    <span className="market_time" style={{marginRight:"15px"}}>Open : {result.open_time}</span>
                                    
                                    
                                    <span className="market_time" >Close : {result.close_time}</span>
                                    </div>
                                </div>
                                { this.state.app_verify !== "0" ? (
                                <div className="inner_market_box" style={{alignItems:"flex-end"}}>
                                    { result.is_close === "1" ?
                                    <Link to={'/play'} state={[{market: result.market, is_open: result.is_open}]}>
                                        <img src="../images/market_open.png" />
                                    </Link>
                                        :  <Link onClick={()=>{marketCloseError()}}>
                                         <img src="../images/market_close.png" />
                                    </Link> }
                                    
                                </div>
                                ) : "" }
                                </div>
                            </div>
                                    )})}

                        </div>

                        <div  className="colored bottomBarMain" style={{padding:"0", justifyContent:"space-between"}}>
                                <div style={{width:"100%"}}>
                                    <div className="row row-cols-5 homemenu" style={{margin:"0", paddingTop:"4px"}}>
                                        <div className="col">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/money (5)-min.png" />
                                                    <span>Deposit</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/withdrawal (3)-min.png"  />
                                                    <span>Withdraw</span>
                                                </div>
                                            </Link>
                                        </div>
                                    
                                        <div className="col">
                                            <Link to={"/#"}>
                                                <div className="homediv">
                                                    <img src="../images/play-button-arrowhead (2).png" />
                                                    <span>Play</span>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col">
                                            <Link to={"/chart"} state={{url:"https://sattads.com/manage/"+"/api/chart2/getChart.php"}}>
                                                <div className="homediv">
                                                    <img src="../images/pie-chart (5)-min.png" />
                                                    <span>Charts</span>
                                                </div>
                                            </Link>
                                        </div>

                                        {/* <div className="col">
                                            <Link to={"/gameHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{padding:"5px"}} />
                                                    <span>History</span>
                                                </div>
                                            </Link>
                                        </div> */}

                                        {/* <div className="col">
                                            <a href={ this.state.whatsapp}>
                                                <div className="homediv">
                                                    <img src="../images/phone (2)-min.png" style={{padding:"5px"}} />
                                                    <span>Whatsapp</span>
                                                </div>
                                            </a>
                                        </div> */}

                                      
                                        <div className="col">
                                        <Link to={"/refer"}>
                                                <div className="homediv">
                                                    <img src="../images/share (6)-min.png" style={{padding:"5px"}} />
                                                    <span>Refer</span>
                                                </div>
                                                </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                                
                            </div>

                    </div>
                </PullToRefresh>

                </div>
            </div>


        </>)
    }

}


export default Home;
